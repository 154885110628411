// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-npc-template-js": () => import("./../src/templates/npcTemplate.js" /* webpackChunkName: "component---src-templates-npc-template-js" */),
  "component---src-templates-session-template-js": () => import("./../src/templates/sessionTemplate.js" /* webpackChunkName: "component---src-templates-session-template-js" */),
  "component---src-templates-game-master-template-js": () => import("./../src/templates/gameMasterTemplate.js" /* webpackChunkName: "component---src-templates-game-master-template-js" */),
  "component---src-templates-ruling-template-js": () => import("./../src/templates/rulingTemplate.js" /* webpackChunkName: "component---src-templates-ruling-template-js" */),
  "component---src-templates-map-template-js": () => import("./../src/templates/mapTemplate.js" /* webpackChunkName: "component---src-templates-map-template-js" */),
  "component---src-templates-character-template-js": () => import("./../src/templates/characterTemplate.js" /* webpackChunkName: "component---src-templates-character-template-js" */),
  "component---src-templates-campaign-template-js": () => import("./../src/templates/campaignTemplate.js" /* webpackChunkName: "component---src-templates-campaign-template-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

